<template>
  <v-container fluid>
    <common-error
      v-if="isError"
      @fetchData="fetchMouDetail"
    />
    <section v-else-if="isLoading">
      <v-row v-for="skeleton in 10" :key="skeleton">
        <v-col cols="12" md="3">
          <v-skeleton-loader
            type="list-item"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="9">
          <v-skeleton-loader
            type="image"
            height="35"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </section>
    <page-not-found
      v-else-if="mouNotFound"
      message="Mou tidak ditemukan"
    />
  </v-container>
</template>

<script>
import PageNotFound from './PageNotFound.vue';

export default {
  name: 'index-mou-detail',
  components: {
    PageNotFound,
  },
  mounted() {
    this.fetchMouDetail();
  },
  data() {
    return {
      resultData: {},
      mouNotFound: false,
      isLoading: false,
      isError: false,
    };
  },
  methods: {
    async fetchMouDetail() {
      try {
        this.isLoading = true;
        this.isError = false;
        const result = await this.$_services.mou.getCooperationInformation();
        this.resultData = result.data;
        this.naviageToPageMou();
      } catch (err) {
        if (err.response && (err.response.status === 404)) {
          this.mouNotFound = true;
          return;
        }
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
    naviageToPageMou() {
      this.$router.replace({
        name: 'mou-detail',
        params: {
          data: this.resultData,
        },
        query: {
          ...this.$route.query,
        },
      });
    },
  },
};
</script>
